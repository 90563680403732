// This file can be replaced during build by using the `fileReplacements` array.
// When building for production, this file is replaced with `environment.prod.ts`.

import { Environment } from './environment.interface';

export const environment: Environment = {
  "metaDescription": "for sex workers by sex workers",
  "metaTitle": "hexd",
  "maxResponses": 1,
  "header": "hexd",
  "app": "hexd",
  "archetype": "mail-list",
  "stage": "prod",
  "numResponses": 1,
  "host": "bewitchd",
  "title": "hexd"
};